import React , {useState, useEffect} from 'react';

// import Feedback from './Feedback';
// import { CopyBlock, CodeBlock, atomOneDark } from "react-code-blocks";


const CircularCheckboxDetails = () => {
  const showLineNumbers =  false;
  const wrapLines =  true;
  const codeBlock = true;
    useEffect(() => {
    
    }, []);

    return (
      <div className="md:flex-grow">

      {/* <Header /> */}
      <div className="text-lg text-gray-600">
        <h2 className="h2 text-gray-900 mb-4 font-bold">Circular Checkbox</h2>

        <p className="mb-8">
          The circular checkbox is used to record user confirmation, featuring a rounded design and animated check effect.
          The checkbox's primary color can also be customized, leading to easy styling of the component's background color.
        </p>
        

        <article className="mb-8 mt-4">
          <h3 id="import" className="text-gray-900 mb-4 text-3xl font-semibold">Import</h3>
          <div style={{fontFamily: 'Fira Code'}}>
            {/* <CopyBlock
              text={`import {CircularCheckbox} from "interakt";`}
              language={'jsx'}
              theme={atomOneDark}
              {...{ showLineNumbers, wrapLines, codeBlock }}
            /> */}
          </div>

        </article>


        <h3 id="installation" className="text-gray-900 mb-4 text-3xl font-semibold" style={{ scrollMarginTop: '100px' }}>Usage</h3>
        <p className="mb-8">
          To include a circular checkbox, simply add the following:
        </p>
        <div style={{fontFamily: 'Fira Code'}}>
            {/* <CopyBlock
              text={`<CircularCheckbox isChecked={false} />`}
              language={'jsx'}
              theme={atomOneDark}
              {...{ showLineNumbers, wrapLines, codeBlock }}
            /> */}
          </div>


        <p className="mb-4 mt-8">
          This will render a checkbox as shown below. Try clicking it to see the animation in action.
        </p>


        <article className="mb-8 mt-8">
          <h3 className="text-gray-900 mb-4 text-3xl font-semibold">Customization</h3>
          <p className="mb-8">
            If you'd like to customize the primary color of the checkbox, this can be done by passing a color 
            to the <code>color</code> prop, as a RGB, RGBA, HEX or CSS color name value:
          </p>

          <div style={{fontFamily: 'Fira Code'}}>
            {/* <CopyBlock
              text={`<CircularCheckbox color={'blue'} />\n<CircularCheckbox color={'rgb(234, 120, 210)'} />\n<CircularCheckbox color={'#f06'} />`}
              language={'jsx'}
              theme={atomOneDark}
              {...{ showLineNumbers, wrapLines, codeBlock }}
            /> */}
          </div>

          <article className="flex pt-8 space-x-4">
            <h1>Content</h1>
          </article>


        </article>


        <h3 id="full_example" className="h3 text-gray-900 mb-4 font-semibold" style={{ scrollMarginTop: '100px' }}>Full Example</h3>
        <p className="mb-8">
          A full example is shown below, where the component is imported and included in the React component:
        </p>

        <div style={{fontFamily: 'Fira Code'}}>

          </div>

        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>Check out the <a className="text-blue-600 hover:underline" href="#props">props</a> below to see how to style the checkbox.</span>
        </p>


        <h3 id="quick" className="h3 text-gray-900 mb-8 font-semibold" style={{ scrollMarginTop: '100px' }}>Props</h3>
        <h4 className="h4 text-gray-900 mb-4 font-bold">isChecked</h4>

        <p className="mb-8">
          <b>isChecked</b> - Boolean - Whether or not the checkbox is checked
        </p>

        <h4 className="h4 text-gray-900 mb-4 font-bold">backgroundColor</h4>
        <p className="mb-8">
          <b>backgroundColor</b>: String - the background color of the circular checkbox, as a CSS color name, RGBA value or HEX code
        </p>

        <h4 className="h4 text-gray-900 mb-4 font-bold">tickColor</h4>
        <p className="mb-8">
          <b>tickColor</b>: String - the tick color for the circular checkbox, as a CSS color name, RGBA value or HEX code
        </p>


      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8 font-semibold">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
          <div>
            <div className="text-normal font-medium mb-1">Folder components</div>
            <div className="text-sm text-gray-600">Learn more about orci ac auctor augue mauris augue neque gravida.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
          <div>
            <div className="text-normal font-medium mb-1">Deploy hooks</div>
            <div className="text-sm text-gray-600">Learn more about risus nullam eget felis eget nunc.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>

      {/* <Feedback /> */}

    </div>
      );
};  

export default CircularCheckboxDetails;