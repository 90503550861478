import React, { useState } from 'react';

import Header from './Header';
import ComponentContent from './ComponentContent';
import Footer from './Footer';
import DocumentationSidebar from './DocumentationSidebar';
import CircularCheckboxDetails from './CircularCheckboxDetails';

function ComponentDetails(props) {
  console.log("props ", props)

  const componentIdToComponent = {"Accordion": <CircularCheckboxDetails />, }

  return (

    <section className="section section--gradient font-inter">

    
      <section className="">
          <div className="pt-4 flex flex-col min-h-screen overflow-hidden">

              {/*  Page content */}
              <main className="flex-grow">

                <section>
                  <div className="max-w-6xl mx-auto md:max-w-7xl">
                    <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                      {/* Main content */}
                      <div className="md:flex md:justify-between" data-sticky-container>

                        <DocumentationSidebar />

                        {componentIdToComponent[props.id]}

                      </div>

                    </div>
                  </div>
                </section>

              </main>

              </div>
    </section>
    <Footer />
    </section>
  );
}

export default ComponentDetails;